import { Badge, Td, Text, Tr } from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import ChainInfo from "components/Chains/ChainInfo";
import { Link } from "react-router-dom";
import { getTimeFromNow } from "utils";
import { getChainIDToName } from "utils/constants";
import { chainIDToName } from "utils/constants";
import { getChainLogo } from "utils/constants";

const RequestRow = ({ row, lastItem }) => {
  const {
    id,
    requestId,
    numCommits,
    indexedAt,
    requestFinalizedEpoch,
    requestFirstCommittedEpoch,
    resultDestinationChainDomain,
    resultRequestingChainDomain,
    resultTarget,
    resultValues,
    status,
    epochConsensusReached,
  } = row;

  return (
    <Tr>
      <Td
        minWidth={{ sm: "250px" }}
        ps="0px"
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {requestId ? <Link to={`/requests/${id}`}>{requestId}</Link> : "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Badge fontSize="sm" p="3px 10px" borderRadius="8px" fontWeight="bold">
          {status ?? "-"}
        </Badge>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <ChainInfo chainId={resultRequestingChainDomain} />
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <ChainInfo chainId={resultDestinationChainDomain} />
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {requestFirstCommittedEpoch ?? "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {requestFinalizedEpoch ?? "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {numCommits ?? "-"}
        </Text>
      </Td>
      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {indexedAt ? getTimeFromNow(indexedAt) : "-"}
        </Text>
      </Td>
    </Tr>
  );
};

export default RequestRow;
