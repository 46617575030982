import Decimal from "decimal.js";
import { Buffer } from "buffer";
import { ethers } from "ethers";

export function convertHexToDecimal(hexString) {
  // Remove the '0x' prefix if present
  if (hexString.startsWith("0x")) {
    hexString = hexString.slice(2);
  }

  // Convert hex string to a Buffer (byte array)
  const byteArray = Buffer.from(hexString, "hex");

  // Convert bytes to string
  const strValue = byteArray.toString("utf8");

  // Check if strValue is empty or invalid
  if (!strValue) {
    return null; // Return null in case of error
  }

  // Parse the string to a Decimal object
  try {
    const decimalValue = new Decimal(strValue);
    return decimalValue; // Return the valid decimal value
  } catch (error) {
    console.error("Error parsing decimal:", error);
    return null; // Return null in case of error
  }
}

export function decodeResultAndPower(resultWithPower) {
  const abiCoder = new ethers.utils.AbiCoder();

  try {
    const res = abiCoder.decode(["uint256", "int8"], resultWithPower);
    return res;
  } catch (error) {
    return null;
  }
}
