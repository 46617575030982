import { Badge, Tooltip } from "@chakra-ui/react";

const { getChainLogo } = require("utils/constants");
const { getChainIDToName } = require("utils/constants");

const ChainInfo = ({ chainId }) => {
  return chainId ? (
    <Tooltip label={chainId} placement="top">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <img
          style={{
            width: "24px",
            height: "24px",
            background: "transparent",
            borderRadius: "50%",
          }}
          src={require(`../../assets/img/chains/${getChainLogo(chainId)}`)}
        />
        <p style={{ marginLeft: "6px" }}>{getChainIDToName(chainId)}</p>
      </div>
    </Tooltip>
  ) : (
    <Badge fontSize="sm" p="3px 10px" borderRadius="8px" fontWeight="bold">
      -
    </Badge>
  );
};

export default ChainInfo;
