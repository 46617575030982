import { useEffect } from "react";
import { MultiSelect } from "chakra-multiselect";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Table,
  TableCaption,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import { useLazyQuery } from "@apollo/client";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import RequestRow from "components/Tables/RequestRow";
import SkeletonTable from "components/Tables/SkeletonTable";
import { useState } from "react";
import { FETCH_REQUESTS } from "utils/queries";
import SearchBar from "components/SearchBar/SearchBar";
import { requestStatusOptions } from "utils/constants";
import { FETCH_REQUESTS_BY_STATUS } from "utils/queries";
import { FETCH_REQUEST } from "utils/queries";
import { FETCH_REQUESTS_BY_STATUS_AND_REQUEST_ID } from "utils/queries";

function Requests() {
  const NUM_REQUESTS = 50;
  const [requestId, setRequestId] = useState(""); // Address filter
  const [requests, setRequests] = useState([]);
  const [option, setOption] = useState([]); // Filter by events
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [getAllRequests] = useLazyQuery(FETCH_REQUESTS);
  const [getAllRequestByStatus] = useLazyQuery(FETCH_REQUESTS_BY_STATUS);
  const [fetchRequestByRequestId] = useLazyQuery(FETCH_REQUEST);
  const [fetchRequestByStatusAndRequestId] = useLazyQuery(
    FETCH_REQUESTS_BY_STATUS_AND_REQUEST_ID
  );

  useEffect(() => {
    console.log({ requestId });
  }, [requestId]);

  const updateRequestsState = (data, currentSkip) => {
    if (data.requests.length < NUM_REQUESTS) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    if (currentSkip === 0) {
      setRequests(data.requests);
    } else {
      setRequests((prevRequests) => [...prevRequests, ...data.requests]);
    }
  };

  const fetchAllRequests = async (currentSkip) => {
    console.log("Fetching all requests");
    console.log({ option, requestId, currentSkip });
    setIsLoading(true);
    const variables = {
      orderBy: `indexedAt`,
      orderDirection: `desc`,
      first: NUM_REQUESTS,
      skip: currentSkip,
    };

    try {
      let data;

      if (option.length > 0) {
        if (requestId) {
          data = await fetchRequestByStatusAndRequestId({
            variables: {
              ...variables,
              status: option,
              requestId: requestId,
            },
          });
        } else {
          data = await getAllRequestByStatus({
            variables: {
              ...variables,
              status: option,
            },
          });
        }
      } else if (requestId) {
        data = await fetchRequestByRequestId({
          variables: {
            ...variables,
            requestId: requestId,
          },
        });
      } else {
        data = await getAllRequests({
          variables,
        });
      }

      updateRequestsState(data.data, currentSkip);
    } catch (error) {
      console.log("Error fetching requests", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMore = () => {
    const newSkip = skip + NUM_REQUESTS;
    setSkip(newSkip); // Update the skip state
    fetchAllRequests(newSkip); // Fetch the next batch
  };

  useEffect(() => {
    setSkip(0);
    fetchAllRequests(0);
  }, [option, requestId]);

  const resetData = () => {
    setSkip(0);
    getAllRequests({
      variables: {
        orderBy: "indexedAt",
        orderDirection: "desc",
        first: NUM_REQUESTS,
        skip: 0,
      },
    }).then((response) => {
      const { data } = response;
      setRequests(data?.requests);
    });
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card
        // overflowX={{ sm: "scroll", xl: "hidden" }}
        pb="0px"
        className="scroll-content"
      >
        <CardHeader p="6px 0px 22px 0px" w="100%" flexDirection="column">
          <Text fontSize="lg" color="#fff" fontWeight="bold">
            Requests
          </Text>
          <Flex justifyContent="space-between" w="100%" mt="30px">
            <SearchBar
              setSearchBarData={() => {}}
              resetData={resetData}
              parent="requests"
              selectedActions={option}
              setFilterAddress={setRequestId}
            />

            <Box className="custom-multi-select">
              <MultiSelect
                options={requestStatusOptions}
                value={option}
                onChange={setOption}
                placeholder="Filter by Event"
                size="md"
                multiple
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color="#fff">
            <TableCaption cursor="pointer" _hover={{ color: "#fff" }}>
              <Button
                onClick={loadMore}
                isLoading={isLoading}
                isDisabled={!hasMore}
              >
                Load More
              </Button>
            </TableCaption>
            <Thead>
              <Tr my=".8rem" ps="0px" color="gray.400">
                <Th
                  ps="0px"
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Request ID
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Status
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Requesting Chain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Desitnation Chain
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  First Committed Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Finalized Epoch
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Number of Commits
                </Th>
                <Th
                  color="gray.400"
                  fontFamily="Plus Jakarta Display"
                  borderBottomColor="#56577A"
                >
                  Indexed At
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && requests.length === 0 ? (
                <SkeletonTable rowCount={10} />
              ) : requests.length === 0 ? (
                <RequestRow row={requests} index={0} lastItem={true} />
              ) : (
                requests.length > 0 &&
                requests.map((row, index, arr) => {
                  return (
                    <RequestRow
                      row={row}
                      key={index}
                      lastItem={index === arr.length - 1 ? true : false}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Requests;
